import "./Assets/scss/style.scss";
import React, { Suspense, lazy, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
import Load from "./Components/Load";
import { useLoginStore } from "./Views/Auth/store";
const Login = lazy(() => import("./Views/Auth/Login"));
const Index = lazy(() => import("./Views/Pages/Index"));
// const Dashboard = lazy(() => import("./Views/Pages/Dashboard/Dashboard"));
const Dashboard = lazy(() => import("./Views/Pages/Dashboard/Dashboard1"));
const Pengguna = lazy(() => import("./Views/Pages/Pengguna/Pengguna"));
const TambahPengguna = lazy(() => import("./Views/Pages/Pengguna/AddPengguna"));
const EditPengguna = lazy(() => import("./Views/Pages/Pengguna/EditPengguna"));
const MasterData = lazy(() => import("./Views/Pages/MasterData/MasterData"));
const Kunjungan = lazy(() => import("./Views/Pages/Kunjungan/Kunjungan"));
const TambahKunjungan = lazy(() =>
  import("./Views/Pages/Kunjungan/AddKunjungan")
);
const EditKunjungan = lazy(() =>
  import("./Views/Pages/Kunjungan/EditKunjungan")
);

const pages = [
  {
    el: <RoleComp el={<MasterData />} role={["1"]} />,
    url: "master-data",
  },
  { el: <Dashboard />, url: "dashboard" },
  { el: <RoleComp el={<Pengguna />} role={["1"]} />, url: "pengguna" },
  {
    el: <RoleComp el={<TambahPengguna />} role={["1"]} />,
    url: "pengguna/add",
  },
  {
    el: <RoleComp el={<EditPengguna />} role={["1"]} />,
    url: "pengguna/edit/:id",
  },

  { el: <RoleComp el={<Kunjungan />} role={["1"]} />, url: "kunjungan" },
  {
    el: <RoleComp el={<TambahKunjungan />} role={["1"]} />,
    url: "kunjungan/add",
  },
  {
    el: <RoleComp el={<EditKunjungan />} role={["1"]} />,
    url: "kunjungan/edit/:id",
  },
];

function RequireAuth({ children }) {
  const [state, action] = useLoginStore();
  const location = useLocation();

  return state.isAuthed === true ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

function RoleComp({ el, role }) {
  const [state, action] = useLoginStore();

  return role.includes(state.profile.role)
    ? el
    : // <Navigate to="/loged/dashboard" replace />
      el;
}

function App() {
  const [state, action] = useLoginStore();

  useEffect(() => {
    action.checkUser();
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Load />}>
        <Routes>
          <Route
            path="/loged"
            element={
              <RequireAuth>
                <Index />
              </RequireAuth>
            }
          >
            {pages.map((page) => (
              <Route path={page.url} element={page.el} />
            ))}
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
